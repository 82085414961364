import React from 'react';

import Layout from '../components/Layout/Layout';
import Seo from '../components/Seo/Seo';
import LogoMobile from '../assets/images/logo-white.inline.svg';

import './index.css';

function ThankYouPage() {
  return (
    <Layout>
      <Seo title="Coming Soon" />

      <div className="thankyou-page">
      <LogoMobile />
      <h1>
        <strong>Gracias</strong>
        </h1>
        <h3>
        Nos contactaremos a la brevedad
        </h3>

        <a href='/' className="btn-link">	&#8592;  Volver</a>
      </div>
    </Layout>
  );
}

export default ThankYouPage;
